import UserClass from './UserClass';

const About = () => {
    return (
        <div>
            <UserClass name="Rahul S Beelur" />
        </div>
    );
};

export default About;
